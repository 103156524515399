import React, { memo } from "react";
import PropTypes from "prop-types";
import { ResponsivePie } from "@nivo/pie";

export const AvatarPatientsByDisease = memo(function AvatarPatientsByDisease_Memo({ data }) {
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 140, bottom: 40, left: 140 }}
      colors={{ scheme: "nivo" }}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      sortByValue={true}
      startAngle={-10}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={8}
      radialLabelsLinkHorizontalLength={4}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: "color" }}
      enableSlicesLabels={true}
      slicesLabelsSkipAngle={25}
      slicesLabelsTextColor="#333333"
      sliceLabel={d => `${d.percent} %`}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      //tooltip={e => {
      //return (
      //<div>{e.id} - {e.value}</div>
      //);
      //}}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }
      ]}
      fill={[]}
    />
  );
});

AvatarPatientsByDisease.propTypes = {
  data: PropTypes.object.isRequired
};

/*
            legends={[
                {
                    anchor: "bottom-right",
                    direction: "column",
                    translateY: 56,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    symbolSize: 18,
                    symbolShape: "circle",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemTextColor: "#000"
                            }
                        }
                    ]
                }
            ]}
            */
