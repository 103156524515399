import React from "react";
import { connect } from "react-redux";
import { Grid, Row, Col } from "react-flexbox-grid";
import Moment from "moment";
import xlsx from "xlsx";
import { saveAs } from "file-saver";
import axios from "axios";

class HistoryBox extends React.Component {
  constructor(props) {
    super(props);
    this.renderItemStatus = this.renderItemStatus.bind(this);
    this.renderDownloadButton = this.renderDownloadButton.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
  }

  handleDownloadClick(e) {
    e.preventDefault();
    axios({
      method: "get",
      url: `https://${__API__}/avatar/id/submissions/${this.props.itemData.submissionID}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Api-Key": this.props.user.apiKey
      }
    })
      .then(response => {
        let wb = new Workbook();
        wb.SheetNames.push("Results");
        wb.Sheets["Results"] = xlsx.utils.json_to_sheet(response.data.items);

        saveAs(
          new Blob(
            [
              xlsx.write(wb, {
                bookType: "xlsx",
                bookSST: false,
                type: "array"
              })
            ],
            { type: "application/octet-stream" }
          ),
          `${this.props.user.company}_AvatarID_${Moment(this.props.itemData.dateSubmitted).format("MMDDYYYY")}.xlsx`
        );
      })
      .catch(err => alert(err));
  }

  renderItemStatus() {
    switch (this.props.itemData.status.toLowerCase()) {
      case "submitted":
        return <span className="fa fa-cloud-upload historyItemIcon" title="Request Submitted" />;
      case "processing":
        return <span className="fa fa-refresh historyItemIcon" title="Request Processing" />;
      case "done":
        return <span className="fa fa-check-circle historyItemIcon" title="Processing Done" />;
      case "warning":
        return <span className="fa fa-exclamation-triangle historyItemIcon" title="Processing Done With Warning(s)" />;
      case "error": {
        let message = `Processing Error: ${this.props.itemData.message}`;
        return <span className="fa fa-exclamation-circle historyItemIcon" title={message} />;
      }
      default:
        return <span className="fa fa-question-circle historyItemIcon" title="Status Unknown" />;
    }
  }

  renderDownloadButton() {
    if (this.props.itemData.submitted > 0 && this.props.itemData.generated > 0) {
      switch (this.props.itemData.status.toLowerCase()) {
        case "done":
        case "warning":
          return <i className="fa fa-cloud-download-alt historyItemIcon" title="Download Avatar ID's" onClick={this.handleDownloadClick} />;
        default:
          return "";
      }
    } else {
      return "";
    }
  }

  render() {
    return (
      <div className="historyItem">
        <Grid fluid>
          <Row>
            <Col xs={7}>
              <Row>
                <Col xs={5}>
                  <b>Source:</b>
                </Col>
                <Col xs={7}>{this.props.itemData.submissionMethod}</Col>
              </Row>
              <Row>
                <Col xs={5}>
                  <b>Date:</b>
                </Col>
                <Col xs={7}>{Moment(this.props.itemData.dateSubmitted).format("MM/DD/YYYY hh:mm")}</Col>
              </Row>
              <Row>
                <Col xs={5}>
                  <b>Requester:</b>
                </Col>
                <Col xs={7}>{this.props.itemData.requester}</Col>
              </Row>
              <Row>
                <Col xs={5}>
                  <b>Avatar ID's Generated:</b>
                </Col>
                <Col xs={7}>
                  {this.props.itemData.generated}
                  &nbsp;of&nbsp;{this.props.itemData.submitted}
                </Col>
              </Row>
            </Col>
            <Col xs={3}>{this.renderItemStatus()}</Col>
            <Col xs={2}>{this.renderDownloadButton()}</Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();

  this.SheetNames = [];
  this.Sheets = {};
}

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps)(HistoryBox);
