import React, { memo } from "react";
import styled from "styled-components";
import { ImportHistory, ImportTable } from "./";
import { useAnalytics } from "../../../hooks";


export const AvatarIDImport = memo(function AvatarIDImport_Memo() {
  //console.log("In AvatarIDImport");
  const { trackEvent } = useAnalytics();

  return (
    <>
      <h2>Avatar ID Generator</h2>
      {__DEV__ && <WarningMessage>Testing Purposes Only</WarningMessage>}
      <Wrapper>
        <SectionWrapper>
          <SectionTitle>Avatar ID Generation History</SectionTitle>
          <ImportHistory />
        </SectionWrapper>
        <SectionWrapper>
          <SectionTitle>Request Avatar ID's</SectionTitle>
          {__DEV__ && <WarningMessage>Test Avatar ID's Only - Cannot be used for actual shipments</WarningMessage>}
          <ImportTable trackEvent={trackEvent}/>
        </SectionWrapper>
      </Wrapper>
    </>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SectionWrapper = styled.div`
  flex-basis: 50%;
  //border: 1px solid orange;
  padding: 1rem;
`;

const SectionTitle = styled.h3`
  text-align: center;
`;

const WarningMessage = styled.div`
  color: #ff0000;
  font-size: 2rem;
  text-align: center;
`;
