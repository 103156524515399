import React, { memo } from "react";
import PropTypes from "prop-types";
import { ResponsiveLine } from "@nivo/line";

export const AvatarPatientsReleaseByMonth = memo(function AvatarPatientsReleaseByMonth_Memo({ data }) {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 40, right: 20, bottom: 80, left: 40 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -54,
        //legend: "transportation",
        legendOffset: 36,
        legendPosition: "middle"
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Patient Count",
        legendOffset: -50,
        legendPosition: "middle"
      }}
      colors={{ scheme: "nivo" }}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      enablePointLabel={false}
      pointLabel="y"
      pointLabelYOffset={-12}
      enableArea={true}
      useMesh={true}
      crosshairType="cross"
      curve="linear"
      legends={[]}
    />
  );
});

AvatarPatientsReleaseByMonth.propTypes = {
  data: PropTypes.object.isRequired
};
