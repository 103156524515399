import * as actionTypes from "./actionTypes";

const defaultState = {
  importHistory: [],
  importRows: [
    {
      avatar_id: "",
      tcc_id: ""
    }
  ]
};

export const reducer = (state = defaultState, action) => {
  let newState = {
    ...state
  };

  switch (action.type) {
    case actionTypes.SAVE_HISTORY: {
      newState.importHistory = action.value;
      return newState;
    }
    case actionTypes.ADD_IMPORT_ROW: {
      newState.importRows = [
        ...newState.importRows,
        {
          avatar_id: action.rowData.avatar_id,
          tcc_id: action.rowData.tcc_id,
          patientConsentDate: action.rowData.patientConsentDate,
          versionSigned: action.rowData.versionSigned,
          tieredConsentCheck: action.rowData.tieredConsentCheck,
          patientStillActivelyConsented: action.rowData.patientStillActivelyConsented,
          acknowledgementDate: action.rowData.acknowledgementDate,
          acknowledgingStaffInitials: action.rowData.acknowledgingStaffInitials,
          primarySiteDesignation: action.rowData.primarySiteDesignation,
          histologyDesignation: action.rowData.histologyDesignation,
          clinicalGroupStage: action.rowData.clinicalGroupStage,
          pathologyGroupStage: action.rowData.pathologyGroupStage,
          patientBeingSeenFor: action.rowData.patientBeingSeenFor,
          performanceStatus: action.rowData.performanceStatus,
          performanceScaleUsed: action.rowData.performanceScaleUsed,
          diseaseStatus: action.rowData.diseaseStatus,
          error: "",
          date_requested: ""
        }
      ];
      return newState;
    }
    case actionTypes.CLEAR_ROWS: {
      newState.importRows = []; //.splice(0, newState.importRows.length);
      return newState;
    }
    case actionTypes.DELETE_IMPORT_ROW: {
      newState.importRows.splice(action.index, 1);
      return newState;
    }
    case actionTypes.DELETE_IMPORT_ROWS: {
      for (let index = 0; index < action.indices.length; index++) {
        newState.importRows.splice(action.indices[index] - index, 1);
      }
      return newState;
    }
    case actionTypes.INSERT_IMPORT_ROW: {
      newState.importRows.splice(action.index, 0, {
        avatar_id: action.rowData.avatar_id,
        tcc_id: action.rowData.tcc_id,
        patientConsentDate: action.rowData.patientConsentDate,
        versionSigned: action.rowData.versionSigned,
        tieredConsentCheck: action.rowData.tieredConsentCheck,
        patientStillActivelyConsented: action.rowData.patientStillActivelyConsented,
        acknowledgementDate: action.rowData.acknowledgementDate,
        acknowledgingStaffInitials: action.rowData.acknowledgingStaffInitials,
        primarySiteDesignation: action.rowData.primarySiteDesignation,
        histologyDesignation: action.rowData.histologyDesignation,
        clinicalGroupStage: action.rowData.clinicalGroupStage,
        pathologyGroupStage: action.rowData.pathologyGroupStage,
        patientBeingSeenFor: action.rowData.patientBeingSeenFor,
        performanceStatus: action.rowData.performanceStatus,
        performanceScaleUsed: action.rowData.performanceScaleUsed,
        diseaseStatus: action.rowData.diseaseStatus,
        error: "",
        date_requested: ""
      });
      return newState;
    }
    case actionTypes.UPDATE_ALL_ROWS: {
      newState.importRows = action.rowData;
      return newState;
    }
    case actionTypes.UPDATE_IMPORT_ROW: {
      newState.importRows[action.index] = {
        avatar_id: action.newRowData.avatar_id,
        tcc_id: action.newRowData.tcc_id,
        patientConsentDate: action.newRowData.patientConsentDate,
        versionSigned: action.newRowData.versionSigned,
        tieredConsentCheck: action.newRowData.tieredConsentCheck,
        patientStillActivelyConsented: action.newRowData.patientStillActivelyConsented,
        acknowledgementDate: action.newRowData.acknowledgementDate,
        acknowledgingStaffInitials: action.newRowData.acknowledgingStaffInitials,
        primarySiteDesignation: action.newRowData.primarySiteDesignation,
        histologyDesignation: action.newRowData.histologyDesignation,
        clinicalGroupStage: action.newRowData.clinicalGroupStage,
        pathologyGroupStage: action.newRowData.pathologyGroupStage,
        patientBeingSeenFor: action.newRowData.patientBeingSeenFor,
        performanceStatus: action.newRowData.performanceStatus,
        performanceScaleUsed: action.newRowData.performanceScaleUsed,
        diseaseStatus: action.newRowData.diseaseStatus,
        error: action.newRowData.error,
        date_requested: action.newRowData.date_requested
      };
      return newState;
    }
    default: {
      return newState;
    }
  }
};
