import * as actionTypes from "./actionTypes";

export function addImportRow(rowData) {
  return {
    type: actionTypes.ADD_IMPORT_ROW,
    rowData
  };
}

export function clearRows() {
  return {
    type: actionTypes.CLEAR_ROWS
  };
}

export function deleteImportData(index) {
  return {
    type: actionTypes.DELETE_IMPORT_ROW,
    index
  };
}

export function deleteImportRows(indices) {
  return {
    type: actionTypes.DELETE_IMPORT_ROWS,
    indices
  };
}

export function insertImportRow(index, rowData) {
  return {
    type: actionTypes.INSERT_IMPORT_ROW,
    index,
    rowData
  };
}

export function updateAllRows(rowData) {
  return {
    type: actionTypes.UPDATE_ALL_ROWS,
    rowData
  };
}

export function updateImportRow(index, newRowData) {
  return {
    type: actionTypes.UPDATE_IMPORT_ROW,
    index,
    newRowData
  };
}

export function saveImportHistory(history) {
  return {
    type: actionTypes.SAVE_HISTORY,
    value: history
  };
}
