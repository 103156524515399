import React from "react";
import { connect } from "react-redux";
import { addImportRow, clearRows, deleteImportData, deleteImportRows, insertImportRow, updateImportRow, updateAllRows } from "../actions";
import { STATE_KEY as AVATAR_ID_IMPORT_STATE_KEY } from "../../AvatarIDImport";
import xlsx from "xlsx";
import { Alert, Button, ControlLabel, FormControl, FormGroup, Label, Table } from "react-bootstrap";
import Moment from "moment";
import axios from "axios";
import styled from "styled-components";
import { saveAs } from "file-saver";

class ImportTableComponent extends React.Component {
  constructor(props) {
    super(props);

    this.addRow = this.addRow.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.formatTableData = this.formatTableData.bind(this);
    this.formatTCCID = this.formatTCCID.bind(this);
    this.formatYesNo = this.formatYesNo.bind(this);
    this.handleAddRow = this.handleAddRow.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
    this.handleDeleteRow = this.handleDeleteRow.bind(this);
    this.handleDeleteSelected = this.handleDeleteSelected.bind(this);
    this.handleExportResults = this.handleExportResults.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
    this.handleAlertShow = this.handleAlertShow.bind(this);
    this.handleNewRequest = this.handleNewRequest.bind(this);
    this.handleRowKeyDown = this.handleRowKeyDown.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleRowSelectedChange = this.handleRowSelectedChange.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.parseCSVdata = this.parseCSVdata.bind(this);
    this.parseCSVfile = this.parseCSVfile.bind(this);
    this.parseXLSXfile = this.parseXLSXfile.bind(this);
    this.pollProgress = this.pollProgress.bind(this);
    this.renderAlert = this.renderAlert.bind(this);
    this.renderImportRow = this.renderImportRow.bind(this);
    this.renderRowCheckbox = this.renderRowCheckbox.bind(this);
    this.renderRowDeleteButton = this.renderRowDeleteButton.bind(this);
    this.renderDeleteSelected = this.renderDeleteSelected.bind(this);
    this.renderSelectAll = this.renderSelectAll.bind(this);
    this.renderSubmitControls = this.renderSubmitControls.bind(this);
    this.renderTCCID = this.renderTCCID.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
    this.tccidExists = this.tccidExists.bind(this);
    this.validateData = this.validateData.bind(this);
    this.validateJSON = this.validateJSON.bind(this);

    //console.log("In constructor");

    if (this.props.importRows.length === 0) {
      this.props.addNewRow(this.validateJSON({ avatar_id: "", tcc_id: "" }));
    }

    this.state = {
      addingNewRow: false,
      done: false,
      hasAvatarIDs: false,
      isValid: false,
      selectedRows: [],
      showAlert: false,
      submitted: false,
      updated: true,
      validating: false
    };
  }

  componentDidUpdate() {
    if (this.props.importRows.length === 0) {
      //console.log("In componentDidUpdate, adding new blank row");
      this.props.addNewRow(this.validateJSON({ avatar_id: "", tcc_id: "" }));
    }
    this.refs.importTable.scrollTop = this.refs.importTable.scrollHeight;
  }

  componentWillUnmount() {
    //console.log("In componentWillUnmount ImportTable");
    this.clearForm();
  }

  addRow() {
    console.trace("In addRow()");
    if (this.state.addingNewRow) {
      this.props.addNewRow({ avatar_id: "", tcc_id: "" });
      this.setState({ addingNewRow: false });
      this.validateData();
    }
  }

  clearErrors() {
    let deleteRows = [];
    this.props.importRows.map((item, index) => {
      if (item.avatar_id.length > 0 || item.error.length > 0) {
        deleteRows.push(index);
      }
    });

    for (let index = 0; index < deleteRows.length; index++) {
      this.props.deleteRow(deleteRows[index] - index);
    }

    this.setState({ showAlert: false });
  }

  clearForm() {
    this.props.clearData();
    this.setState({
      addingNewRow: false,
      done: false,
      hasAvatarIDs: false,
      isValid: false,
      selectedRows: [],
      showAlert: false,
      submitted: false,
      updated: true,
      validating: false
    });
    this.props.addNewRow(this.validateJSON({ avatar_id: "", tcc_id: "" }));
    if (this.state.refreshIntervalID && this.state.refreshIntervalID.length > 0) {
      clearInterval(this.state.refreshIntervalID);
    }
  }

  formatTableData() {
    let tableRows = [];
    for (let i = 0; i < this.props.importRows.length; i++) {
      tableRows.push(this.renderImportRow(this.props.importRows[i], i));
    }

    return tableRows;
  }

  formatTCCID(tccid) {
    switch (this.props.user.company) {
      case "CINJ":
        return tccid.padStart(10, "0");
      case "MCC":
        return tccid.padStart(6, "0");
      default:
        return tccid;
    }
  }

  formatYesNo(input) {
    if (input.indexOf("Y") >= 0 || input.indexOf("y") >= 0) return "Yes";
    if (input.indexOf("N") >= 0 || input.indexOf("n") >= 0) return "No";

    return null;
  }

  getItemStatus() {
    return new Promise((resolve, reject) => {
      let requests = [];

      this.props.importRows.map(item => {
        if (item.tcc_id.length > 0) {
          requests.push(item.tcc_id);
        }
      });

      axios({
        method: "get",
        url: `https://${__API__}/avatar/id`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": this.props.user.apiKey,
          "X-ID-Values": requests.join(",")
        }
      })
        .then(response => {
          return resolve(response.data);
        })
        .catch(err => {
          this.setState({ validating: false, showAlert: true });
          reject(err);
        });
    });
  }

  handleAddRow(e) {
    e.preventDefault();
    this.setState(
      {
        addingNewRow: true
      },
      () => {
        this.addRow();
      }
    );
  }

  handleAlertClose() {
    this.setState({ showAlert: false });
  }

  handleAlertShow() {
    this.setState({ showAlert: true });
  }

  handleClearForm(e) {
    e.preventDefault();

    if (confirm("Clear all Avatar ID Request data?")) {
      this.clearForm();
    }
  }

  handleDeleteRow(rowIndex) {
    if (confirm(`Delete Row ${rowIndex}?`)) {
      this.props.deleteRow(rowIndex);

      this.setState({ selectedRows: [], tableRows: [], updated: true });
      this.validateData();
      this.forceUpdate();
    }
  }

  handleDeleteSelected(e) {
    e.preventDefault();
    if (confirm("Delete Selected Rows?")) {
      this.setState({
        selectedRows: this.state.selectedRows.sort((a, b) => {
          return a - b;
        }),
        updated: true
      });

      this.props.deleteRows(this.state.selectedRows);
      this.validateData();
    }

    this.state.selectedRows.splice(0, this.state.selectedRows.length);
    this.forceUpdate();
  }

  handleExportResults(e) {
    e.preventDefault();

    let avatarIDRows = [];
    const fileName = `${this.props.user.company}_AvatarID_${Moment().format("MMDDYYYY")}.xlsx`;

    this.props.importRows.map(item => {
      if (item.avatar_id.length > 0) {
        avatarIDRows.push({ tccid: item.tcc_id, avatarid: item.avatar_id, dateGenerated: item.date_requested, error: item.error });
      }
    });

    if (avatarIDRows.length > 0) {
      let wb = new Workbook();
      wb.SheetNames.push("Results");
      wb.Sheets["Results"] = xlsx.utils.json_to_sheet(avatarIDRows);

      saveAs(
        new Blob(
          [
            xlsx.write(wb, {
              bookType: "xlsx",
              bookSST: false,
              type: "array"
            })
          ],
          { type: "application/octet-stream" }
        ),
        fileName
      );
      this.props.trackEvent("Avatar IDs", `Exported Avatar IDs file name: ${fileName}`, this.props.user.userName);
    }
  }

  handleFileChange(e) {
    let filenameParts = e.target.files[0].name.split(".");
    switch (filenameParts[filenameParts.length - 1]) {
      case "csv":
        this.parseCSVfile(e.target.files[0]);
        break;
      case "xlsx":
        this.parseXLSXfile(e.target.files[0]);
        break;
    }

    e.target.value = null;
    this.forceUpdate();
  }

  handleNewRequest(e) {
    e.preventDefault();

    if (confirm("Clear existing data? This will prevent you from exporting current data")) {
      this.clearForm();
    }
  }

  handleRowChange(e) {
    let index = parseInt(e.target.id);

    if (index >= 0) {
      if (e.target.value.length > 0) {
        let matchingtccid = this.props.importRows.filter(item => {
          return item.tcc_id === e.target.value;
        });

        this.props.updateRow(
          index,
          Object.assign({}, this.props.importRows[index], {
            tcc_id: e.target.value,
            avatar_id: "",
            date_requested: "",
            error: matchingtccid.length > 0 ? "Duplicate TCC ID" : ""
          })
        );
        /*} else if (index !== this.props.importRows.length - 1) {
                this
                    .props
                    .deleteRow(index);
            } */
      } else {
        this.props.deleteRow(index);

        if (this.props.importRows.length === 0) {
          this.addRow();
        }
      }

      this.validateData();
    }

    if (e.target.defaultValue.length === 0) {
      this.setState({ addingNewRow: true });
    }

    this.setState({ updated: true });
    this.forceUpdate();
  }

  handleRowKeyDown(e) {
    if (e.key === "Tab") {
      e.preventDefault();
      this.addRow();
    }
  }

  handleRowSelectedChange(rowIndex) {
    if (this.state.selectedRows.indexOf(rowIndex) >= 0) {
      let index = this.state.selectedRows.indexOf(rowIndex);
      this.state.selectedRows.splice(index, 1);
    } else {
      this.state.selectedRows.push(rowIndex);
    }

    this.forceUpdate();
  }

  handleSelectAll(e) {
    this.state.selectedRows.splice(0, this.state.selectedRows.length);

    if (e.target.checked) {
      for (let i = 0; i < this.props.importRows.length; i++) {
        if (this.props.importRows[i].avatar_id.length === 0) {
          this.state.selectedRows.push(i);
        }
      }
    }

    this.forceUpdate();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ hasAvatarIDs: false, validating: true });
    this.getItemStatus()
      .then(response => {
        if (response.hasErrors) {
          this.props.trackEvent("Avatar IDs", "Request Avatar IDs pre-validation failed", this.props.user.userName);
          response.results.map(item => {
            for (let index = 0; index < this.props.importRows.length; index++) {
              if (this.props.importRows[index].tcc_id === item.tcc_id) {
                let newRow = Object.assign({}, this.props.importRows[index], {
                  avatar_id: item.avatar_id,
                  error: item.error,
                  date_requested: item.date_requested
                });

                this.props.updateRow(index, newRow);
                if (item.avatar_id.length > 0) {
                  this.setState({ hasAvatarIDs: true });
                }
                break;
              }
            }
          });

          if (this.props.importRows[this.props.importRows.length - 1].tcc_id.length > 0) {
            this.props.addNewRow(this.validateJSON({ avatar_id: "", tcc_id: "" }));
          }
          this.setState({ updated: false, validating: false, showAlert: true });
        } else {
          this.props.trackEvent("Avatar IDs", "Request Avatar IDs pre-validation succeeded", this.props.user.userName);
          this.setState({ validating: false, showAlert: false });
          this.submitRequest();
        }
      })
      .catch(err => {
        this.props.trackEvent("Avatar IDs", "Request Avatar IDs API request failed", this.props.user.userName);
        console.error(err);
      });
  }

  parseCSVdata(fileData) {
    let fileRows = fileData.split(/\r?\n/);

    let valid = 0;
    for (let row in fileRows) {
      let rowColumns = fileRows[row].split(",");

      if (rowColumns[0] !== "TCCID" && rowColumns[0].length > 0 && !this.tccidExists(this.formatTCCID(rowColumns[0]))) {
        let rowData = this.validateJSON({
          avatar_id: "",
          tcc_id: this.formatTCCID(rowColumns[0]),
          patientConsentDate: rowColumns[2] ? rowColumns[2] : "",
          versionSigned: rowColumns[3] ? rowColumns[3] : "",
          tieredConsentCheck: rowColumns[4] ? this.formatYesNo(rowColumns[4]) : "",
          patientStillActivelyConsented: rowColumns[5] ? this.formatYesNo(rowColumns[5]) : "",
          acknowledgementDate: rowColumns[6] ? rowColumns[6] : "",
          acknowledgingStaffInitials: rowColumns[7] ? rowColumns[7] : "",
          primarySiteDesignation: rowColumns[8] ? rowColumns[8] : "",
          histologyDesignation: rowColumns[9] ? rowColumns[9] : "",
          clinicalGroupStage: rowColumns[10] ? rowColumns[10] : "",
          pathologyGroupStage: rowColumns[11] ? rowColumns[11] : "",
          patientBeingSeenFor: rowColumns[12] ? rowColumns[12] : "",
          performanceStatus: rowColumns[13] ? rowColumns[13] : "",
          performanceScaleUsed: rowColumns[14] ? rowColumns[14] : "",
          diseaseStatus: rowColumns[15] ? rowColumns[15] : "",
          errors: ""
        });

        if (rowData.errors.length === 0) {
          valid++;
          this.props.insertImportRow(this.props.importRows.length - 1, rowData);
        }
      }
    }

    if (valid <= 0) {
      alert("Invalid File Format");
    }
  }

  parseCSVfile(file) {
    let fr = new FileReader();
    fr.onload = evt => {
      this.parseCSVdata(evt.target.result);
    };
    fr.readAsText(file);
  }

  parseXLSXfile(file) {
    let fr = new FileReader();
    fr.onload = evt => {
      let workbook = xlsx.read(evt.target.result, { type: "binary" });
      this.parseCSVdata(xlsx.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]));
      this.forceUpdate();
    };
    fr.readAsBinaryString(file);
  }

  pollProgress() {
    let refreshIntervalID = setInterval(() => {
      this.getItemStatus()
        .then(response => {
          if (response.results.length === 0) {
            clearInterval(refreshIntervalID);
            this.setState({ done: true });
            this.forceUpdate();
          }

          this.props.updateRows(response.results);

          let requestDone = true;
          this.props.importRows.map(rowData => {
            if (!rowData.error && (!rowData.avatar_id || rowData.avatar_id.length <= 0)) {
              requestDone = false;
            }
          });

          if (requestDone) {
            clearInterval(this.state.refreshIntervalID);
            this.setState({ done: true });
            this.forceUpdate();
          }
        })
        .catch(err => console.error(err));
    }, 5000);
    this.setState({ refreshIntervalID: refreshIntervalID });
  }

  renderAlert() {
    if (!this.state.showAlert) {
      return "";
    }

    return (
      <tr>
        <td colSpan="5">
          <Alert bsStyle="danger" onDismiss={this.handleAlertClose}>
            <p className="alert-title">Validation Errors</p>
            <p>
              <i className="fas fa-exclamation-circle icon" />
              &nbsp; Your entries were not submitted. Please correct errors and try again.
            </p>
            <p className="text-center no-margin">
              <Button onClick={this.clearErrors} className="btn-sm">
                Delete Items With Errors
              </Button>
            </p>
          </Alert>
        </td>
      </tr>
    );
  }

  renderAttachFileControl() {
    if (this.state.done || this.state.submitted || this.state.validating) {
      return "";
    } else {
      return (
        <tr>
          <td />
          <td colSpan="4">
            <FormGroup controlId="AttachFileControl" className="file-input">
              <ControlLabel bsClass="btn">Select File</ControlLabel>
              <span>Upload a .csv or .xlsx file.</span>
              <FormControl id="AttachFileControl" type="file" onChange={this.handleFileChange} accept=".xlsx, .csv" />
            </FormGroup>
          </td>
        </tr>
      );
    }
  }

  renderDeleteSelected() {
    if (!this.state.submitted) {
      return <i className="fa fa-trash-alt fa-lg" onClick={this.handleDeleteSelected} title="Delete Selected Rows" />;
    } else {
      return "";
    }
  }

  renderImportRow(rowData, index) {
    //console.log("In renderImportRow. rowData, index, this.state", rowData, index, this.state);
    if (this.state.submitted && rowData.tcc_id.length === 0) {
      return "";
    } else {
      let className = "";

      //if (!this.state.done && rowData.tcc_id.length > 0) {
      if (rowData.avatar_id.length > 0 || (rowData.error && rowData.error.length > 0)) {
        className = "table-danger";
      }
      //}

      return (
        <tr key={index} className={className}>
          <td>
            <center>{this.renderRowCheckbox(rowData, index)}</center>
          </td>
          <td>{this.renderTCCID(rowData, index)}</td>
          <td>
            {rowData.avatar_id} {rowData.error && <RowErrorMessage>{rowData.error}</RowErrorMessage>}
          </td>
          <td>{!rowData.avatar_id ? "" : rowData.date_requested ? Moment(rowData.date_requested).format("MM/DD/YYYY") : ""}</td>
          <td>
            <center>{this.renderRowDeleteButton(rowData, index)}</center>
          </td>
        </tr>
      );
    }
  }

  renderRowCheckbox(rowData, index) {
    if (!this.state.submitted) {
      return <input type="checkbox" id="selectAll" ref="selectAll" onClick={() => this.handleRowSelectedChange(index)} checked={this.state.selectedRows.indexOf(index) >= 0} />;
    } else {
      return "";
    }
  }

  renderRowDeleteButton(rowData, index) {
    if (!this.state.submitted) {
      return <i className="fa fa-trash-alt fa-lg" title="Delete Row" onClick={() => this.handleDeleteRow(index)} />;
    } else {
      return "";
    }
  }

  renderSelectAll() {
    if (!this.state.submitted) {
      return <input type="checkbox" onClick={this.handleSelectAll} />;
    } else {
      return "";
    }
  }

  renderSubmitControls() {
    if (this.state.done) {
      return (
        <tfoot>
          <tr>
            <td />
            <td colSpan="4">
              <div>
                <span>
                  <i className="fa fa-check-circle" />
                  &nbsp;Done Processing
                </span>
                &nbsp;&nbsp;
                <Button bsStyle="primary" onClick={this.handleExportResults}>
                  Export Avatar ID's
                </Button>
                <Button className="btn-secondary" onClick={this.handleNewRequest}>
                  New Request
                </Button>
              </div>
            </td>
          </tr>
        </tfoot>
      );
    }

    if (this.state.submitted || this.state.validating) {
      return (
        <tfoot>
          <tr>
            <td />
            <td colSpan="4">
              <span>
                <i className="fa fa-refresh fa-spin fa-fw" />
                &nbsp;Processing...
              </span>
            </td>
          </tr>
        </tfoot>
      );
    }

    //if (this.props.importRows.length > 1) {
    return (
      <tfoot>
        <tr>
          <td />
          <td colSpan="4">
            <span>
              <Button className="btn-secondary" onClick={this.handleClearForm}>
                Clear Form
              </Button>
              <Button bsStyle="primary" onClick={this.handleSubmit} disabled={this.state.showAlert}>
                Submit
              </Button>
              <Button bsStyle="primary" onClick={this.handleExportResults} hidden={!this.state.hasAvatarIDs}>
                Export Avatar ID's
              </Button>
            </span>
          </td>
        </tr>
      </tfoot>
    );
    //} return (null);
  }

  renderTCCID(rowData, index) {
    if (!this.state.submitted) {
      return (
        <div>
          <input type="textbox" placeholder="Enter TCC ID" value={rowData.tcc_id} onChange={this.handleRowChange} onKeyDown={this.handleRowKeyDown} ref={`importRow${index}`} autoFocus={rowData.tcc_id.length === 0} id={index} />
          &nbsp;
          <Button bsClass="btn btn-default btn-sm add-row-button" onClick={this.handleAddRow} hidden={index < this.props.importRows.length - 1 || rowData.tcc_id.length === 0}>
            Add Row
          </Button>
        </div>
      );
    } else {
      return <Label title={rowData.error}>{rowData.tcc_id}</Label>;
    }
  }

  submitRequest() {
    this.setState({ isValid: true });

    if (this.props.importRows[this.props.importRows.length - 1].tcc_id.length === 0) {
      this.props.deleteRow(this.props.importRows.length - 1);
    }

    axios({
      method: "post",
      url: `https://${__API__}/avatar/id`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": this.props.user.apiKey
      },
      data: {
        user: this.props.user.userName,
        datasource: this.props.user.company,
        requests: this.props.importRows,
        submissionMethod: "web"
      }
    })
      .then(response => {
        this.props.trackEvent("Avatar IDs", "Request Avatar IDs succeeded", this.props.user.userName);
        this.pollProgress();
      })
      .catch(err => {
        this.props.trackEvent("Avatar IDs", "Request Avatar IDs failed", this.props.user.userName);
        console.error(err);
      });

    this.setState({ submitted: true });
    this.forceUpdate();
  }

  tccidExists(tccid) {
    for (let row in this.props.importRows) {
      if (this.props.importRows[row].tcc_id === tccid) return true;
    }

    return false;
  }

  validateData() {
    let showExport = false;
    let showAlert = false;

    this.props.importRows.map(item => {
      if (item.error && item.error.length > 0) {
        showAlert = true;
      }

      if (item.avatar_id && item.avatar_id.length > 0) {
        showAlert = true;
        showExport = true;
      }
    });

    this.setState({ hasAvatarIDs: showExport, showAlert: showAlert });
  }

  validateJSON(rowData) {
    // JNP 3/1/19 - Removed pending confirmation that date format is not needed
    /* if (rowData.acknowledgementDate) {
            let acknowledgementDate = new Date(rowData.acknowledgementDate);
            if (!isNaN(acknowledgementDate.valueOf())) {
                rowData.acknowledgementDate = Moment(acknowledgementDate).format('yyyy-MM-dd');
            } else {
                rowData.errors = rowData
                    .errors
                    .concat('Invalid Acknowledgement Date\r\n');
            }
        } */

    const dateregex = /^(0?[0-9]|1[0-2])[/-](0?[0-9]|[12][0-9]|3[0-1])[/-]((20)?[0-9]{2})/;
    if (!dateregex.test(rowData.acknowledgementDate)) rowData.acknowledgementDate = null;
    if (!dateregex.test(rowData.patientConsentDate)) rowData.patientConsentDate = null;

    //console.log(rowData);

    return rowData;
  }

  render() {
    return (
      <div ref="importTable">
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th>
                <center>{this.renderSelectAll()}</center>
              </th>
              <th>TCC ID</th>
              <th>Avatar ID</th>
              <th>Date Avatar ID Generated</th>
              <th>
                <center>{this.renderDeleteSelected()}</center>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.formatTableData()}
            {this.renderAlert()}
            {this.renderAttachFileControl()}
          </tbody>
          {this.renderSubmitControls()}
        </Table>
      </div>
    );
  }
}

const RowErrorMessage = styled.div`
  color: #ff0000;
  font-weight: 500;
`;

function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();

  this.SheetNames = [];
  this.Sheets = {};
}

function mapStateToProps(state) {
  return {
    importRows: state[AVATAR_ID_IMPORT_STATE_KEY].importRows,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addNewRow: newRowData => {
      dispatch(addImportRow(newRowData));
    },
    clearData: () => {
      dispatch(clearRows());
    },
    deleteRow: id => {
      dispatch(deleteImportData(id));
    },
    deleteRows: indices => {
      dispatch(deleteImportRows(indices));
    },
    insertImportRow: (index, newRowData) => {
      dispatch(insertImportRow(index, newRowData));
    },
    updateRow: (index, newRowData) => {
      dispatch(updateImportRow(index, newRowData));
    },
    updateRows: rowData => {
      dispatch(updateAllRows(rowData));
    }
  };
}

const ImportTable = connect(mapStateToProps, mapDispatchToProps)(ImportTableComponent);
export { ImportTable };
