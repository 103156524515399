import React, { memo } from "react";
import PropTypes from "prop-types";
import { ResponsiveBar } from "@nivo/bar";

export const AvatarV2V4Progress = memo(function AvatarV2V4Progress_Memo({ data, keys }) {
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy="version"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      layout="vertical"
      colors={{ scheme: "nivo" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#ffffff",
          size: 4,
          padding: 1,
          stagger: true
        },
        {
          id: "lines",
          type: "patternLines",
          color: "#42a0ffaa",
          rotation: -45,
          lineWidth: 6,
          spacing: 7
        }
      ]}
      fill={[
        {
          match: {
            id: "DOESNOTEXIST - V2 & V4"
          },
          id: "dots"
        }
      ]}
      borderColor={{ from: "color", modifiers: [["darker", "1.6"]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Data Version",
        legendPosition: "middle",
        legendOffset: 32
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Patient Count",
        legendPosition: "middle",
        legendOffset: -50
      }}
      enableGridX={true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", "1.6"]] }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  );
});

AvatarV2V4Progress.propTypes = {
  data: PropTypes.object.isRequired
};
