import React, { memo } from "react";
import PropTypes from "prop-types";

export const VennDiagram = memo(function VennDiagram_Memo({ data }) {
  const defaultData = { title: "", value: "" };
  const leftData = data[0] || defaultData;
  const middleData = data[1] || defaultData;
  const rightData = data[2] || defaultData;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 100 100"
      height="400px"
      width="100%"
      //style={{maxHeight: '600px'}}
    >
      <circle cx="33%" cy="50%" r="33%" fill="rgba(255,127,14,0.25)" />
      <circle cx="66%" cy="50%" r="33%" fill="rgba(31,119,180,0.25)" />
      <text x="23%" y="50%" dy="0" textAnchor="middle" style={{ fontSize: "3px", fontWeight: "bold" }}>
        {leftData.title}
      </text>
      <text x="23%" y="50%" dy="8" textAnchor="middle" style={{ fontSize: "3px" }}>
        {leftData.value}
      </text>
      <text x="50%" y="50%" dy="0" textAnchor="middle" style={{ fontSize: "3px", fontWeight: "bold" }}>
        {middleData.title}
      </text>
      <text x="50%" y="50%" dy="8" textAnchor="middle" style={{ fontSize: "3px" }}>
        {middleData.value}
      </text>
      <text x="76%" y="50%" dy="0" textAnchor="middle" style={{ fontSize: "3px", fontWeight: "bold" }}>
        {rightData.title}
      </text>
      <text x="76%" y="50%" dy="8" textAnchor="middle" style={{ fontSize: "3px" }}>
        {rightData.value}
      </text>
    </svg>
  );
});
