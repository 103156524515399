import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useInterval } from "../../../hooks";
import { STATE_KEY as USER_STATE_KEY } from "../../User";
import { STATE_KEY as AVATAR_ID_IMPORT_STATE_KEY, HistoryBox } from "../../AvatarIDImport";
import * as actions from "../actions";
import axios from "axios";

export const ImportHistory = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state[USER_STATE_KEY]);
  const avatarIDImport = useSelector(state => state[AVATAR_ID_IMPORT_STATE_KEY]);
  const importHistory = avatarIDImport.importHistory;

  const getImportHistory = () => {
    axios({
      method: "get",
      url: `https://${__API__}/avatar/id/submissions`,
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": user.apiKey
      }
    })
      .then(response => {
        dispatch(actions.saveImportHistory(response.data));
      })
      .catch(err => alert(err));
  };

  useEffect(() => {
    getImportHistory();
  }, []);

  return (
    <>
      {importHistory.length < 1 && <div>No Avatar ID Request History Found</div>}
      {importHistory.map(item => (
        <HistoryBox key={item.submissionID} itemData={item} />
      ))}
    </>
  );
};
