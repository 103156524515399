import React, { useEffect, useMemo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AvatarV2V4Progress, AvatarPatientsByDisease, AvatarPatientsByMolecularStatus, AvatarPatientsByNucleicType, AvatarPatientsReleaseByMonth, VennDiagram, STATE_KEY as AVATAR_STATE_KEY } from "../../Avatar";
import { STATE_KEY as USER_STATE_KEY } from "../../User";
import ContentLoader from "react-content-loader";
import * as actions from "../actions";
import axios from "axios";
import * as Utils from "../../../data/utilities.js";
import moment from "moment";
import styled from "styled-components";
import { AbilityContext, subjects, Can } from "../../../ability";

export const Avatar = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state[USER_STATE_KEY]);
  const avatarSelector = useSelector(state => state[AVATAR_STATE_KEY]);
  const summaryData = avatarSelector.summary;
  const totalsData = avatarSelector.totals;
  const diseasesData = avatarSelector.diseases;
  const releasesData = avatarSelector.releases;
  const ability = useContext(AbilityContext);

  // Prepare data for charts ----------------------------
  const avatarV2V4ProgressVennDiagramData = useMemo(
    () => [
      { title: "V2", value: summaryData.V2ReleasedOnly },
      { title: "V2 & V4", value: summaryData.V2andV4Released },
      { title: "V4", value: summaryData.V4ReleasedOnly }
    ],
    [summaryData]
  );
  const avatarV2V4ProgressData = useMemo(
    () => [
      {
        version: "V2",
        V2: summaryData.V2ReleasedOnly,
        "V2 & V4": summaryData.V2andV4Released
      },
      {
        version: "V4",
        V4: summaryData.V4ReleasedOnly,
        "V2 & V4": summaryData.V2andV4Released
      }
    ],
    [summaryData]
  );
  const avatarV2V4ProgressKeys = useMemo(() => ["V2 & V4", "V2", "V4"], []);

  const avatarPatientsByDiseaseData = useMemo(
    () =>
      totalsData.Diseases
        ? totalsData.Diseases.map(d => {
            return {
              id: d.DiseaseType,
              label: d.DiseaseType,
              value: d.total,
              percent: d.percent
            };
          })
        : [],
    [totalsData]
  );

  const avatarPatientsByMolecularStatusData = useMemo(
    () => [
      {
        id: "WESTumor",
        label: "WESTumor",
        value: totalsData.WESTumor && totalsData.WESTumor.total ? totalsData.WESTumor.total : 0,
        percent: totalsData.WESTumor && totalsData.WESTumor.percent ? totalsData.WESTumor.percent : 0
      },
      {
        id: "RNA",
        label: "RNA",
        value: totalsData.RNA && totalsData.RNA.total ? totalsData.RNA.total : 0,
        percent: totalsData.RNA && totalsData.RNA.percent ? totalsData.RNA.percent : 0
      },
      {
        id: "WESGermline",
        label: "WESGermline",
        value: totalsData.WESGermline && totalsData.WESGermline.total ? totalsData.WESGermline.total : 0,
        percent: totalsData.WESGermline && totalsData.WESGermline.percent ? totalsData.WESGermline.percent : 0
      }
    ],
    [totalsData]
  );

  const avatarPatientsReleaseByMonthDataReduce = useMemo(
    () =>
      releasesData.reduce((accumulator, currentValue) => {
        //const currentDate = new Date(currentValue.AvatarDataReleaseDate);
        //const currentKey = `${currentDate.getFullYear()}-${currentDate.getMonth() +
        //1}`;
        const currentDate = moment(currentValue.AvatarDataReleaseDate);
        const currentKey = currentDate.format("MMM YYYY"); // Eg. "Dec 2019"
        const currentCount = currentValue.PatientCount || 0;
        const newCount = accumulator[currentKey] ? accumulator[currentKey] + currentCount : currentCount;

        return {
          ...accumulator,
          [currentKey]: newCount
        };
      }, {}),
    [releasesData]
  );

  const avatarPatientsReleaseByMonthData = useMemo(
    () => [
      {
        id: "AvatarPatientsReleaseByMonth",
        data: Object.keys(avatarPatientsReleaseByMonthDataReduce).map(key => {
          return {
            x: key,
            y: avatarPatientsReleaseByMonthDataReduce[key]
          };
        })
      }
    ],
    [avatarPatientsReleaseByMonthDataReduce]
  );

  const avatarPatientsByNucleicTypeColumns = useMemo(
    () => [
      { title: "Cancer Group", key: "DiseaseType", dataKey: "DiseaseType", width: 200, frozen: "left", resizable: true, flexGrow: 2 },
      { title: "WES Tumor", key: "WESTumor", dataKey: "WESTumor", width: 100, resizable: true, flexGrow: 1 },
      { title: "RNA", key: "RNA", dataKey: "RNA", width: 100, resizable: true, flexGrow: 1 },
      { title: "WES Germline", key: "WESGermline", dataKey: "WESGermline", width: 100, resizable: true, flexGrow: 1 },
      { title: "V2 Patients", key: "PatientCount_V2Released", dataKey: "PatientCount_V2Released", width: 100, resizable: true, flexGrow: 1 },
      { title: "V4 Patients", key: "PatientCount_V4Released", dataKey: "PatientCount_V4Released", width: 100, resizable: true, flexGrow: 1 },
      { title: "Total Patients", key: "TotalPatientCount", dataKey: "TotalPatientCount", width: 100, resizable: true, flexGrow: 1 }
    ],
    []
  );
  const avatarPatientsByNucleicTypeData = useMemo(() => diseasesData, [diseasesData]);

  // Fetch all the data ---------------------------------
  const axiosInstance = axios.create({
    baseURL: `https://${__GENERICAPI__}/orienavatar/${user.userType}/${user.company}/avatar`,
    headers: {
      Authorization: user.apiKey
    }
  });

  const fetchSummaryDataHelper = () => {
    return new Promise((resolve, reject) => {
      //console.log("Promise for fetchSummaryDataHelper");
      Utils.fetchSummaryData(axiosInstance, [], resolve, reject);
    });
  };

  const fetchDetailDataHelper = (dataType, maxPageIndex, startingPageIndex, initialData) => {
    return new Promise((resolve, reject) => {
      //console.log("fetchDetailDataHelper", dataType, maxPageIndex, startingPageIndex);
      Utils.fetchPaginatedDetailData({
        axiosInstance,
        dataType,
        initialData,
        maxPageIndex,
        currentPageIndex: startingPageIndex,
        resolve,
        reject
      });
    });
  };

  useEffect(() => {
    // if the data has already been loaded then exit out of this effect.
    const { summary, patient, disease, releases } = avatarSelector.loaded;
    if (summary && patient && disease && releases) {
      return; // exit out of this effect
    }

    const startingPageIndex = 0;

    // fetch summary information
    dispatch(actions.setDataTypeLoading("summary", true));
    dispatch(actions.setDataTypeLoaded("summary", false));
    fetchSummaryDataHelper().then(response => {
      const { summary, totals, patientpagecount: patientPageCount = 0, diseasepagecount: diseasePageCount = 0, releasespagecount: releasesPageCount = 0 } = response;
      dispatch(actions.setSummaryData(summary));
      dispatch(actions.setDataTypeLoading("summary", false));
      dispatch(actions.setDataTypeLoaded("summary", true));

      dispatch(actions.setTotalsData(totals));
      dispatch(actions.setDataTypeLoading("totals", false));
      dispatch(actions.setDataTypeLoaded("totals", true));

      // fetch Patient Details
      dispatch(actions.setDataTypeLoading("patient", true));
      dispatch(actions.setDataTypeLoaded("patient", false));
      fetchDetailDataHelper("patient", patientPageCount - 1, startingPageIndex, []).then(response => {
        //console.log(`${Utils.DATATYPE.PATIENT} - ${response.length} records`);
        dispatch(actions.setPatientData(response));
        dispatch(actions.setDataTypeLoading("patient", false));
        dispatch(actions.setDataTypeLoaded("patient", true));
      });

      // fetch Disease Details
      dispatch(actions.setDataTypeLoading("disease", true));
      dispatch(actions.setDataTypeLoaded("disease", false));
      fetchDetailDataHelper("disease", diseasePageCount - 1, startingPageIndex, []).then(response => {
        //console.log(`${Utils.DATATYPE.DISEASE} - ${response.length} records`);
        dispatch(actions.setDiseaseData(response));
        dispatch(actions.setDataTypeLoading("disease", false));
        dispatch(actions.setDataTypeLoaded("disease", true));
      });

      // fetch Releases Details
      dispatch(actions.setDataTypeLoading("releases", true));
      dispatch(actions.setDataTypeLoaded("releases", false));
      fetchDetailDataHelper("releases", releasesPageCount - 1, startingPageIndex, []).then(response => {
        //console.log(`${Utils.DATATYPE.RELEASES} - ${response.length} records`);
        dispatch(actions.setReleasesData(response));
        dispatch(actions.setDataTypeLoading("releases", false));
        dispatch(actions.setDataTypeLoaded("releases", true));
      });
    });
  }, []); // only run this effect once

  return (
    <>
      <h2>Released Avatar Patients Summary</h2>
      <ChartingGrid>
        <Can I="view" a={subjects.ROLE_M2GEN}>
          {!avatarSelector.loaded.summary ? (
            <SkeletonContentPieChart />
          ) : (
            <div>
              <h3>Avatar V2 V4 Progress</h3>
              <VennDiagram data={avatarV2V4ProgressVennDiagramData} />
            </div>
          )}
        </Can>
        <Can I="view" a={subjects.ROLE_M2GEN}>
          {!avatarSelector.loaded.summary ? (
            <SkeletonContentBarChart />
          ) : (
            <ChartWrapper>
              <h3>Avatar V2 V4 Progress</h3>
              <AvatarV2V4Progress data={avatarV2V4ProgressData} keys={avatarV2V4ProgressKeys} />
            </ChartWrapper>
          )}
        </Can>
        {!avatarSelector.loaded.totals ? (
          <SkeletonContentPieChart />
        ) : (
          <ChartWrapper className="avatar-chart" style={{ padding: "50px" }}>
            <h3>Current Avatar Patients by Disease</h3>
            <AvatarPatientsByDisease data={avatarPatientsByDiseaseData} />
          </ChartWrapper>
        )}
        {!avatarSelector.loaded.disease ? (
          <SkeletonContentTable />
        ) : (
          <ChartWrapper>
            <h3>Current Avatar Patients by Nucleic Type</h3>
            <TableWrapper>
              <AvatarPatientsByNucleicType columns={avatarPatientsByNucleicTypeColumns} data={avatarPatientsByNucleicTypeData} />
            </TableWrapper>
          </ChartWrapper>
        )}
        {!avatarSelector.loaded.totals ? (
          <SkeletonContentPieChart />
        ) : (
          <ChartWrapper>
            <h3>Current Avatar Patients by Molecular Status</h3>
            <AvatarPatientsByMolecularStatus data={avatarPatientsByMolecularStatusData} tempdata={[]} />
          </ChartWrapper>
        )}
      </ChartingGrid>
      {!avatarSelector.loaded.releases ? (
        <SkeletonContentLineChart />
      ) : (
        <ChartFullWidthWrapper>
          <h3>Avatar Patients Release by Month</h3>
          <AvatarPatientsReleaseByMonth data={avatarPatientsReleaseByMonthData} />
        </ChartFullWidthWrapper>
      )}
    </>
  );
};

const SkeletonContentPieChart = () => {
  return (
    <ContentLoader viewBox="0 0 1000, 600">
      <rect x="0" y="0" rx="0" ry="0" width="400" height="30" />
      <circle cx="300" cy="300" r="230" />
    </ContentLoader>
  );
};

const SkeletonContentBarChart = () => {
  return (
    <ContentLoader viewBox="0 0 360, 300">
      <rect x="20" y="5" rx="0" ry="0" width="1" height="170" />
      <rect x="20" y="175" rx="0" ry="0" width="360" height="1" />

      <rect x="40" y="75" rx="0" ry="0" width="35" height="100" />
      <rect x="80" y="125" rx="0" ry="0" width="35" height="50" />
      <rect x="120" y="105" rx="0" ry="0" width="35" height="70" />
      <rect x="160" y="35" rx="0" ry="0" width="35" height="140" />
      <rect x="200" y="55" rx="0" ry="0" width="35" height="120" />
      <rect x="240" y="15" rx="0" ry="0" width="35" height="160" />
      <rect x="280" y="135" rx="0" ry="0" width="35" height="40" />
      <rect x="320" y="85" rx="0" ry="0" width="35" height="90" />
    </ContentLoader>
  );
};

const SkeletonContentLineChart = () => {
  return (
    <ContentLoader viewBox="0 0 400, 120">
      <rect x="0" y="0" rx="5" ry="5" width="400" height="100" />
    </ContentLoader>
  );
};

const SkeletonContentTable = () => {
  return (
    <ContentLoader viewBox="0 0 1500 400">
      <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
      <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
      <rect x="1424" y="137" rx="10" ry="10" width="68" height="19" />
      <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="203" r="12" />
      <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="265" r="12" />
      <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="323" r="12" />
      <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
      <circle cx="1456" cy="386" r="12" />
      <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
      <rect x="1183" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="1182" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="1305" y="137" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="194" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="256" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="314" rx="10" ry="10" width="85" height="19" />
      <rect x="1304" y="377" rx="10" ry="10" width="85" height="19" />
      <circle cx="37" cy="97" r="11" />
      <rect x="26" y="23" rx="5" ry="5" width="400" height="30" />
      <circle cx="1316" cy="88" r="11" />
      <rect x="1337" y="94" rx="0" ry="0" width="134" height="3" />
      <circle cx="77" cy="96" r="11" />
    </ContentLoader>
  );
};

const ChartingGrid = styled.div`
  margin-top: 40px;
  display: grid;
  //width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); //repeat(2, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 80px;
  grid-column-gap: 40px;
  padding: 20px;
`;

const ChartWrapper = styled.div`
  height: 400px;
  padding-bottom: 30px;
  margin-bottom: 10px;
`;

const ChartFullWidthWrapper = styled.div`
  width: 100%;
  height: 400px;
  margin-top: 80px;
  padding: 20px;
`;

const TableWrapper = styled.div`
  margin-top: 20px;
  height: calc(100% - 20px);

  .BaseTable {
    &__header-row {
      background-color: #35a093;
      color: #ffffff;
      font-weight: 500;
    }

    &__:nth-of-type(odd) {
      background-color: #f6f6f6 !important;
    }

    &__row-cell,
    &__header-cell {
      border-top: 1px solid #eeeeee;
      border-left: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      border-right: none;
      justify-content: center;
    }
  }
`;
